<template lang="pug">
  .row
    .filter.col-xl-4.col-lg-4.col-md-12
      .filter-title {{ $t("plan_settings.basic_settings.shop") }}
      AppDropdown.car-class-name(
        v-if="disabledSelect"
        :placeholder="$t('plan_settings.basic_settings.select_car_class')"
        :disabled="true"
        v-b-tooltip.hover
        :title="$t('ota.no_available_otas')"
      )
      AppDropdown.shop-name(
        v-else
        close-on-select
        searchable
        :loading="shopsLoading"
        :value="selectedShop"
        :items="shopsItems"
        :placeholder="$t('plan_settings.basic_settings.select_shop')"
        @select="$emit('select-shop', $event)"
        @remove="$emit('select-shop', {})"
      )
    .filter.col-xl-4.col-lg-4.col-md-12
      .filter-title {{ $t("plan_settings.basic_settings.car_class") }}
      AppDropdown.car-class-name(
        v-if="!filters.shop_id"
        :placeholder="$t('plan_settings.basic_settings.select_car_class')"
        :disabled="true"
        v-b-tooltip.hover
        :title="$t('plan_settings.basic_settings.select_shop_first')"
      )
      AppDropdown.car-class-name(
        v-else
        close-on-select
        searchable
        :loading="carClassesLoading"
        :placeholder="$t('plan_settings.basic_settings.select_car_class')"
        :value="selectedCarClass"
        :items="carClassesItems"
        :is-item-disabled="isItemDisabled"
        order-direction="preorder"
        @select="$emit('select-car-class', $event)"
        @remove="$emit('select-car-class', {})"
      )
      //- TODO: uncomment checkboxes on work them
      //- .checkboxes
        AppCheckbox.checkbox(
          :disabled="isActionsDisabled"
          :label="$t('plan_settings.basic_settings.apply_to_all_shops')"
          :value="filters.all_shops"
          @change="setAllShops"
        )
        AppCheckbox.checkbox(
          :disabled="isActionsDisabled"
          :label="$t('plan_settings.basic_settings.apply_to_all_car_classes')"
          :value="filters.all_car_classes"
          @change="setAllCarClasses"
        )
    .save-button.col-xl-4.col-lg-4.col-md-12
      .filter-title
      AppSaveButton(
        short
        title="actions.save_changes"
        :disabled="disabledSave"
        @save="$emit('save')"
      )
</template>

<script>
  //misc
  import { isEmpty, find } from "lodash-es"

  export default {
    props: {
      filters: Object,
      shopsItems: {
        type: Array,
        default: () => []
      },
      carClassesItems: {
        type: Array,
        default: () => []
      },
      shopsLoading: {
        type: Boolean,
        default: false
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      },
      disabledSave: {
        type: Boolean,
        default: false
      },
      disabledSelect: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      CheckboxesFilter: () => import("@/components/InventoryManagement/CheckboxesFilter"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    computed: {
      isActionsDisabled() {
        return !(this.filters.shop_id && this.filters.car_class_id)
      },

      selectedShop() {
        return find(this.shopsItems, { id: this.filters.shop_id })
      },

      selectedCarClass() {
        return find(this.carClassesItems, { id: this.filters.car_class_id })
      }
    },

    methods: {
      isEmpty,

      isItemDisabled({ selectable }) {
        return !selectable
      },

      setAllShops() {
        this.applyFilters({ all_shops: !this.filters.all_shops })
      },

      setAllCarClasses() {
        this.applyFilters({ all_car_classes: !this.filters.all_car_classes })
      },

      applyFilters(newFilters) {
        this.$emit("change-filters", { ...this.filters, ...newFilters })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .row
    margin: unset

  .radio-buttons
    .item
      .title
        font-size: 0.8rem

  .filter,
  .save-button
    +filter-title
    padding: 0

  .save-button
    text-align: right

    // make button in one line with dropdowns
    .app-button
      margin-top: 7px
</style>
